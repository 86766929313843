import * as React from 'react';
import { Avatar, BottomNavigation, BottomNavigationAction, Box, CircularProgress, Dialog, Divider, Fade, Grid, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Modal, p, Paper, Tab, Tabs, Typography } from "@mui/material";
import { addTag, getAllTags, getAyatsByChapterId } from './Api';
import CloseIcon from '@mui/icons-material/Close';
import $ from 'jquery';
import { List, AutoSizer, CellMeasurerCache, CellMeasurer, WindowScroller } from 'react-virtualized';
//import { VariableSizeList as List } from "react-window";
import { ArrowForward, AutoStories, CommentOutlined, CopyAllOutlined, Favorite, FavoriteBorderOutlined, FavoriteOutlined, LocationOn, NextWeek, Note, NotesOutlined, Person, PersonPin, Phone, Restore, Send, TextSnippet } from '@mui/icons-material';
import Notes from './Notes';
import bRed from "./assets/images/popupicons/bookmark-tooltip-circle-red.svg";
import bBlue from "./assets/images/popupicons/bookmark-tooltip-circle-blue.svg";
import bGold from "./assets/images/popupicons/bookmark-tooltip-circle-yellow.svg";
import './App.css';
import { Button } from 'react-bootstrap';

const style = {
    listContainer: {
        height: "100vh",
        width: "100%"
    },
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: "90%", sm: "90%", md: 400 },
        overflow: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: '25px'
    }
};


var modexText = ""
var arabicTextArray = []
var ayatIdArray = []
var transiliterationArray = []
var translationArray = []
var uniqueAyatsArray = []
var uniqueGroupsArray = []

var arabicTextGroupArray = []
var transiliterationGroupArray = []
var translationGroupArray = []
var prevTopic = ""
var prevSubTopic = ""
var highlightedText = ""
var textToCopy = ""
var selectedPhraseId = 0;
var selectedPhrase = 0;
function Chapter({ mode, chapter, chapters, isArabicOn, isTransiliterationOn, isTranslationOn, viewType, setViewType, fontSize, arabicFontSize, fontFamily, col, selectedAyatNumber, setCol, handleTags, handleIsUserLoginCheck, searchTerm, setSelectedChapter, setSelectedAyatNumber }) {


    const [allTags, setAllTags] = React.useState([]);

    const getBookMarkTag = (ayatNumber) => {
        if (allTags?.length > 0) {
            const tag = allTags.find(item => item.ayat_number == ayatNumber) || null;
            if (tag?.label === "RED") {
                return <img src={bRed} width={15} height={10} />

            }
            else if (tag?.label === "BLUE") {
                return <img src={bBlue} width={15} height={10} />

            }
            else if (tag?.label === "GOLD") {
                return <img src={bGold} width={15} height={10} />
            }
        }
    };
    React.useEffect(() => {
        if (typeof chapter !== 'undefined') {
            // yourVariable is undefined
            getAllTagsFromServer()
        }

    }, [chapter]);

    React.useEffect(() => {

        localStorage.setItem("selectedChapter", JSON.stringify(chapter));
        localStorage.setItem("selectedAyatNumber", selectedAyatNumber);

    }, [chapter, selectedAyatNumber]);

    function getAllTagsFromServer() {
        const formData = new FormData();
        formData.append('user_id', localStorage.getItem("token"))
        getAllTags(formData).then((result) => {
            setAllTags(result.tags)

        }).catch(console.error.bind(console))
    }

    console.log("selectedAyatNumber:" + selectedAyatNumber)
    console.log("selectedChapter:" + chapter.id)

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 500
    });

    console.log(fontSize)
    const [scrollToIndex, setScrollToIndex] = React.useState(selectedAyatNumber);
    const [scrollFlag, setScrollFlag] = React.useState(false);
    const [checked, setChecked] = React.useState({ isLoading: true, show: false });
    const [viewState, setViewState] = React.useState({ typeOfView: viewType, isLoading: true, allAyats: [], uniqueAyats: [], ayatClicked: false });

    // model state
    const [open, setOpen] = React.useState(false);
    const handleOpen = (text) => {
        modexText = text
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    // view state changed
    React.useEffect(() => {
        setViewState({ ...viewState, typeOfView: viewType })
    }, [viewType]);

    // Monitor changes to selectedAyatNumber
    React.useEffect(() => {
        setScrollFlag(true); // Set the flag to true on change
    }, [selectedAyatNumber]);




    function createJS($count, $mode) {

        $(function () {
            $('.item' + $count).hover(function () {
                $('.allITems').css('color', $mode == 'dark' ? '#fff' : '#000');
                $(this).css('color', '#FF8C00');
                $('.itemT' + $count).css('color', '#FF8C00');

            },
                function () {
                    // $(this).css('color', $mode == 'dark' ? '#fff' : '#000');


                });
        });
        $(function () {
            $('.itemT' + $count).hover(function () {
                $('.allITems').css('color', $mode == 'dark' ? '#fff' : '#000');
                $(this).css('color', '#FF8C00');
                $('.item' + $count).css('color', '#FF8C00');
                $('.itemT' + $count).css('color', '#FF8C00');

            },
                function () {
                    // $(this).css('color', $mode == 'dark' ? '#fff' : '#000');
                    //$('.item' + $count).css('color', $mode == 'dark' ? '#fff' : '#000');
                    //$('.itemT' + $count).css('color', $mode == 'dark' ? '#fff' : '#000');

                });

        });
    }

    function parseGroupList(allAyats, groupNumber) {
        var arabicTextFinalText = ""
        var transiliterationFinalText = ""
        var translationFinalText = ""

        var arabicTextFinal = []
        var transiliterationFinal = []
        var translationFinal = []
        allAyats?.map((ayat2, count) => {
            if (groupNumber === ayat2.groupNumber) {

                arabicTextFinalText = arabicTextFinalText + "<span class='item" + count + " allITems' style = 'cursor: auto;'>" + ayat2.arabicText.replace("<p>", "").replace("</p>", "") + " </span>";
                arabicTextFinal.push({
                    ayatId: ayat2.ayatId,
                    ayatNumber: ayat2.ayatNumber,
                    text: "<span class='item" + count + " allITems' style = 'cursor: auto;'>" + ayat2.arabicText.replace("<p>", "").replace("</p>", "") + " </span>"
                });
                // if (ayat2.ayatBreakPoint == "START" || ayat2.ayatBreakPoint == "TOTAL") {
                //     transiliterationFinal = transiliterationFinal + "<span class='ayahNumberEng'> " + ayat2.ayatNumber + ". </span>";
                // }
                // transiliterationFinal = transiliterationFinal + "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + ayat2.transiliteration.replace("<p>", "").replace("</p>", "") + " </span>";
                // translationFinal = translationFinal + "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + ayat2.translation.replace("<p>", "").replace("</p>", "") + " </span>" + ayatRefrenceGroup(ayat2, count);
                // if (ayat2.ayatBreakPoint == "END" || ayat2.ayatBreakPoint == "TOTAL") {
                //     arabicTextFinal = arabicTextFinal + "<span class='ayahNumber'>" + Number(ayat2.ayatNumber).toLocaleString('ar-u-nu-arab') + "  </span>";
                // }

                if (ayat2.ayatBreakPoint == "START" || ayat2.ayatBreakPoint == "TOTAL") {
                    transiliterationFinalText = transiliterationFinalText + "<span class='ayahNumberEng'> " + ayat2.ayatNumber + ". </span>";
                    transiliterationFinal.push(
                        {
                            ayatNumber: ayat2.ayatNumber,
                            reference: ayat2.reference,
                            text: "<span class='ayahNumberEng'> " + ayat2.ayatNumber + ". </span>"
                        }
                    );
                }

                transiliterationFinalText = transiliterationFinalText + "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + ayat2.transiliteration.replace("<p>", "").replace("</p>", "") + " </span>";
                transiliterationFinal.push({
                    ayatNumber: ayat2.ayatNumber,
                    ayatId: ayat2.ayatId,
                    reference: ayat2.reference,
                    text: "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + ayat2.transiliteration.replace("<p>", "").replace("</p>", "") + " </span>"
                }
                );

                if (ayat2.ayatBreakPoint == "START" || ayat2.ayatBreakPoint == "TOTAL") {
                    translationFinal.push(
                        {
                            text: "<span class='ayahNumberEng'> " + ayat2.ayatNumber + ". </span>"
                        }
                    );
                }
                translationFinal.push({
                    ayatNumber: ayat2.ayatNumber,
                    reference: ayat2.reference,
                    text: "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + ayat2.translation.replace("<p>", "").replace("</p>", "") + " </span>" + ayatRefrenceGroup(ayat2, count)
                });

                if (ayat2.ayatBreakPoint == "END" || ayat2.ayatBreakPoint == "TOTAL") {
                    arabicTextFinalText = arabicTextFinalText + "<span class='ayahNumber' style='margin: 1px; padding: 8px;'>" + Number(ayat2.ayatNumber).toLocaleString('ar-u-nu-arab') + "  </span>";
                    arabicTextFinal.push({
                        ayatNumber: ayat2.ayatNumber,
                        ayatId: ayat2.ayatId,
                        reference: ayat2.reference,
                        text: "<span class='ayahNumber' style='margin: 1px; padding: 8px;'>" + Number(ayat2.ayatNumber).toLocaleString('ar-u-nu-arab') + "  </span>"
                    }
                    );
                }
                //arabicTextFinal.push(arabicTextFinalText);

            }

        })

        arabicTextGroupArray.push(arabicTextFinal.sort((a, b) => a.ayatNumber - b.ayatNumber))
        transiliterationGroupArray.push(transiliterationFinal.sort((a, b) => a.ayatNumber - b.ayatNumber))
        translationGroupArray.push(translationFinal.sort((a, b) => a.ayatNumber - b.ayatNumber))


    }

    function parseGroupListJS(groupNumber) {

        viewState.allAyats?.map((ayat2, count) => {
            if (groupNumber === ayat2.groupNumber) {
                createJS(count, mode)
            }
        })
    }


    function parseAyatsListJS(ayatNumber) {

        viewState.allAyats?.map((ayat2, count) => {
            if (ayatNumber === ayat2.ayatNumber) {
                createJS(count, mode)
            }
        })
    }

    function parseAyatsList(allAyats, ayatNumber) {
        var arabicTextFinal = []
        var transiliterationFinal = []
        var translationFinal = []


        allAyats?.map((ayat2, count) => {
            if (ayatNumber === ayat2.ayatNumber) {
                arabicTextFinal.push({
                    ayatId: ayat2.ayatId,
                    text: "<span class='item" + count + " allITems' style = 'cursor: auto;'>" + ayat2.arabicText.replace("<p>", "").replace("</p>", "") + " </span>"
                });
                transiliterationFinal.push(
                    {
                        ayatId: ayat2.ayatId,
                        text: "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + ayat2.transiliteration.replace("<p>", "").replace("</p>", "") + " </span>"
                    }
                );
                translationFinal.push(
                    {
                        ayatId: ayat2.ayatId,
                        reference: ayat2.reference,
                        text: "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + ayat2.translation.replace("<p>", "").replace("</p>", "") + " </span>"
                    });

            }
        })

        arabicTextFinal.push("<span class='ayahNumber'>" + Number(ayatNumber).toLocaleString('ar-u-nu-arab') + "</span>");
        arabicTextArray.push(arabicTextFinal)
        transiliterationArray.push(transiliterationFinal)
        translationArray.push(translationFinal)

    }

    // network call
    React.useEffect(() => {

        if (typeof chapter.id !== 'undefined') {
            // yourVariable is undefined


            setChecked({ isLoading: true, show: false })
            uniqueAyatsArray = []
            arabicTextArray = []
            ayatIdArray = []
            transiliterationArray = []
            translationArray = []

            uniqueGroupsArray = []
            arabicTextGroupArray = []
            transiliterationGroupArray = []
            translationGroupArray = []

            getAyatsByChapterId(chapter.id).then((result) => {
                var allAyats = []
                result.ayats?.map((ayat, index) => {
                    ayat.topicTitle = topic(ayat.topicTitle)
                    ayat.subTopicTitle = subTopic(ayat.subTopicTitle)

                    //ayat.translation = highlightSearchTerm(ayat.translation)
                    //ayat.reference = highlightSearchTerm(ayat.reference)
                    //ayat.context = highlightSearchTerm(ayat.context)

                    if (uniqueAyatsArray.findIndex(item => item.ayatNumber == ayat.ayatNumber) === -1) {

                        uniqueAyatsArray.push(ayat)
                        parseAyatsList(result.ayats, ayat.ayatNumber)
                    }
                    if (uniqueGroupsArray.findIndex(item => item.groupNumber == ayat.groupNumber) === -1) {
                        uniqueGroupsArray.push(ayat)
                        parseGroupList(result.ayats, ayat.groupNumber)
                    }

                    allAyats.push(ayat)
                });
                var _allAyats = allAyats.sort((a, b) => a.ayatNumber - b.ayatNumber)
                setViewState({ ...viewState, uniqueAyats: uniqueAyatsArray, allAyats: _allAyats })
                setChecked({ isLoading: false, show: true })


            }).catch(console.error.bind(console))
        }

    }, [chapter.id, chapter]);


    const ayatRefrenceGroup = (ayat2, num) => {
        return "";
        //return (ayat2?.reference.trim() !== "") ? "<sup class='reference refGroup' > [" + num + "]</sup> " : ""
    }

    const ayatRefrence = (ayat2, num) => {
        return (ayat2?.reference && ayat2?.reference?.trim() !== "") ? <sup className='reference' onClick={(event) => { handleOpen(ayat2?.reference) }} key={Date.now() + Math.random()}> {"[ref]  "}</sup> : ""
    }

    const arabicText1 = (arabicText, count) => {
        createJS(count, mode)
        return "<span class='item" + count + " allITems' style = 'cursor: auto;'>" + arabicText.replace("<p>", "").replace("</p>", "") + "</span>"
    }
    const transiliteration1 = (transiliteration, count) => {
        return "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + transiliteration.replace("<p>", "").replace("</p>", "") + "</span>"
    }

    const translation1 = (translation, count) => {

        return "<span class='itemT" + count + " allITems' style = 'cursor: auto;'>" + translation.replace("<p>", "").replace("</p>", "") + "</span>"
    }




    function highlightSearchTerm(fString) {
        if (!searchTerm || !fString) {
            return fString;
        }
        let regex = new RegExp(searchTerm, 'gi');
        return fString.replace(regex, `<span style="color:#FF8C00"><b>${searchTerm}</b></span>`);
    }




    const topic = (_topic) => {
        if (prevTopic !== _topic && _topic !== "NA") {
            prevTopic = _topic
            return _topic
        }
    }

    const subTopic = (_subTopic) => {
        if (prevSubTopic !== _subTopic && _subTopic !== "NA") {
            prevSubTopic = _subTopic
            return _subTopic
        }
    }


    React.useEffect(() => {
        const saveSelection = () => {
            if (window.getSelection().toString() != "") {
                highlightedText = window.getSelection().toString()
            }
        };
        document.addEventListener('mouseup', saveSelection);

    }, []);


    function AyatViewRow({ index, windowWidth, handleBottomNavChange }) {

        const ayat = uniqueAyatsArray[index]

        parseAyatsListJS(ayat?.ayatNumber)
        const rowRef = React.useRef();


        React.useEffect(() => {
            setSize(index, rowRef.current.getBoundingClientRect().height);
        }, [setSize, index, windowWidth]);

        // menu start
        const [contextMenu, setContextMenu] = React.useState(null);
        const [ayatId, setAyatId] = React.useState(0);
        const [phrase, setPhrase] = React.useState("");

        const handleContextMenu = (event, ayatId, text) => {
            event.preventDefault();
            // Remove the custom color when the context menu is triggered
            let parentContainer = event.target.closest('.MuiGrid-root.MuiGrid-container'); // Adjust if necessary
            if (parentContainer) {
                let parent = parentContainer?.parentElement?.closest('.MuiGrid-root.MuiGrid-container');
                if (parent) {
                    parentContainer = parent;
                }
                const spanElements = parentContainer.querySelectorAll('span.allITems');
                spanElements.forEach((spanElement) => {
                    spanElement.style.color = "inherit";
                });
            }
            console.log(ayatId);
            setAyatId(ayatId)
            setPhrase(text)
            textToCopy = text
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            );
        };

        const handleClose = () => {
            setContextMenu(null);
        };


        const onClickPopupIcon = (e) => {

            selectedPhraseId = 0
            selectedPhraseId = ayatId
            selectedPhrase = phrase

            if (e == "Notes" || e == "Commentary") {
                setCol(3)
                handleBottomNavChange(e, e)
            }
            else if (e == "CopyAll") {
                const phraseToCopy = viewState.allAyats.find(item => item.ayatId === ayatId)
                //    console.log(phraseToCopy)
                const regex = /<[^>]*>/g;

                const transli = isTranslationOn ? phraseToCopy["translation"].replace(regex, '') + "\n" : ''
                const tran = isTransiliterationOn ? phraseToCopy["transiliteration"].replace(regex, '') + "\n" : ''
                const arb = isArabicOn ? phraseToCopy["arabicText"].replace(regex, '') + "\n" : ''

                navigator.clipboard.writeText(arb + tran + transli)
            }
            else {
                const regex = /<[^>]*>/g;
                navigator.clipboard.writeText(textToCopy.replace(regex, ''))
            }

            handleClose()

        };

        const onClickTag = (tag, ayatId) => {
            handleIsUserLoginCheck()
            console.log(tag, ayatId);
            handleClose()

            const formData = new FormData();
            formData.append('label', tag)
            formData.append('user_id', localStorage.getItem("token"))
            formData.append('ayat_number', ayatId)

            addTag(formData).then((result) => {

                getAllTagsFromServer()

            }).catch(console.error.bind(console))
        };


        return (<div ref={rowRef}>

            {index == 0 ?
                (<Grid item xs={12} sm={12} md={12}
                    align="center">
                    <Typography variant='title' align="center">
                        {chapter.title}
                    </Typography> <br />
                    <Typography variant='titleArabic' align="center" style={{ fontFamily: fontFamily, fontSize: 50, fontStyle: 'italic' }}>
                        {chapter.arabicTitle}
                    </Typography><br />
                    <Typography variant='subTopic' align="center" style={{ fontFamily: fontFamily }}>
                        {chapter.translation}
                    </Typography>

                </Grid>) : <></>}
            <Grid item xs={12} sm={12} md={12}
                align="center">
                <Grid item xs={12} sm={12} md={12} align="center">
                    {ayat.juzzTitle && ayat.juzzTitle !== "" && (
                        <Typography variant="topic" align="center">
                            {ayat.juzzTitle}
                        </Typography>
                    )}
                    {ayat.juzzTitle && <br />}
                    {ayat.juzzTitle && !ayat.topicTitle && <> <br /><br /></>}

                    {ayat.topicTitle && ayat.topicTitle !== "" && (
                        <Typography variant="topic" align="center" style={{ fontSize: 25, fontWeight: 700 }}>
                            {ayat.topicTitle}
                        </Typography>
                    )}

                    {ayat.topicTitle && !ayat.subTopicTitle && <> <br /><br /></>}
                    {ayat.subTopicTitle && <br />}
                    {ayat.subTopicTitle && (
                        <Typography variant="subTopic" align="center" style={{ fontSize: 20, fontWeight: 500 }}>
                            {ayat.subTopicTitle}
                        </Typography>
                    )}
                    {ayat.subTopicTitle && <> <br /><br /><br /></>}
                    {
                        ayat.context !== '' &&
                        <Box px={2} >
                            <Typography variant={'contextText'} style={{ fontSize: fontSize }} align="left" dangerouslySetInnerHTML={{
                                __html: highlightSearchTerm(ayat.context)
                            }} />
                            <br />
                        </Box>
                    }
                </Grid>

            </Grid>
            <Grid item xs={12} sm={12} md={12} key={index} align="center" px={5} py={3}>
                <Grid item xs={12} sm={12} md={12} key={index} style={style} align="center">
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ cursor: 'context-menu' }}>
                        {(isTransiliterationOn || isTranslationOn) ?
                            <Grid container xs={12} sm={12} md={(isArabicOn) ? 6 : 12} order={{ xs: 2, sm: 2, md: 1 }} align="left">
                                {isTransiliterationOn ?

                                    <Grid item xs={12} sm={12} md={(isArabicOn || !isTranslationOn) ? 12 : 6} align="left" >
                                        {transiliterationArray[index]?.map((item, index2) => (

                                            <>
                                                <Typography variant='transiliteration' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                                    __html: ((index2 == 0 ? "<span class='ayahNumberEng'>" + uniqueAyatsArray[index]?.ayatNumber + ". </span>" : ""))

                                                }} >
                                                </Typography>
                                                <sup>
                                                    {getBookMarkTag(item?.ayatId)}
                                                </sup>
                                                <Typography variant='transiliteration' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                                    __html: item.text

                                                }} onContextMenu={(event) => handleContextMenu(event, item.ayatId, item.text)}>
                                                </Typography>
                                            </>

                                        ))}

                                    </Grid>
                                    : null}
                                {isTranslationOn ?
                                    <Grid item xs={12} sm={12} md={(isArabicOn || !isTransiliterationOn) ? 12 : 6} align="left">

                                        {translationArray[index]?.map((item, index2) => (

                                            <>
                                                {isTransiliterationOn ? "" : <> <Typography variant='translation' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                                    __html: ((index2 == 0 ? "<span class='ayahNumberEng'>" + uniqueAyatsArray[index]?.ayatNumber + ". </span>" : ""))

                                                }} >
                                                </Typography>
                                                    <sup>
                                                        {getBookMarkTag(item?.ayatId)}
                                                    </sup>
                                                </>}

                                                <Typography variant='translation' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                                    __html: highlightSearchTerm(item.text)
                                                }} onContextMenu={(event) => handleContextMenu(event, item.ayatId, item.text)}>
                                                </Typography>
                                                {ayatRefrence(item, index)}
                                            </>))}
                                    </Grid> : null}
                            </Grid> : null}
                        {isArabicOn ?
                            <Grid item xs={12} sm={12} md={(isTransiliterationOn || isTranslationOn) ? 6 : 12} order={{ xs: 1, sm: 1, md: 2 }} align="right" dir="rtl">
                                {arabicTextArray[index]?.map((item, index2) => (

                                    <>

                                        <Typography variant='arabic' style={{ fontSize: arabicFontSize, fontFamily: fontFamily }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.text
                                            }}

                                            onContextMenu={(event) => handleContextMenu(event, item.ayatId, item.text)}
                                        >
                                        </Typography>
                                        <Typography variant='arabic' dir="rtl" style={{ fontSize: arabicFontSize, fontFamily: fontFamily }}
                                            dangerouslySetInnerHTML={{
                                                __html: (index2 == arabicTextArray[index].length - 1 ? " <span class='ayahNumber' style ='margin:7px'> " + Number(uniqueAyatsArray[index]?.ayatNumber).toLocaleString('ar-u-nu-arab') + "</span> " : "")
                                            }}
                                            onContextMenu={(event) => handleContextMenu(event, item.ayatId)}  >
                                        </Typography>
                                    </>))}
                            </Grid> : null}
                    </Grid>
                </Grid>
                <Divider />
            </Grid>

            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={() => onClickPopupIcon("Copy")}><CopyAllOutlined /> &nbsp;Copy</MenuItem>
                <MenuItem onClick={() => onClickPopupIcon("CopyAll")}><CopyAllOutlined /> &nbsp;CopyAll</MenuItem>
                <MenuItem onClick={() => onClickPopupIcon("Notes")}><NotesOutlined /> &nbsp;Notes</MenuItem>
                {/* <MenuItem onClick={() => onClickPopupIcon("Commentary")}><CommentOutlined /> &nbsp;Commentary</MenuItem> */}
                <Divider />
                <Grid item xs={12} sm={12} md={12} align="center" >
                    <img src={bRed} width={25} height={10} p={5} onClick={() => onClickTag("Red", ayatId)} style={{ cursor: "pointer" }} />
                    <img src={bBlue} width={25} height={10} p={5} onClick={() => onClickTag("Blue", ayatId)} style={{ cursor: "pointer" }} />
                    <img src={bGold} width={25} height={10} p={5} onClick={() => onClickTag("Gold", ayatId)} style={{ cursor: "pointer" }} />
                </Grid>

            </Menu>

        </div>)

    }


    function PhraseViewRow({ index, windowWidth, handleBottomNavChange }) {
        // const rowRef = React.useRef();
        createJS(index, mode)
        // React.useEffect(() => {
        //     setSize(index, rowRef.current.getBoundingClientRect().height);
        // }, [setSize, index, windowWidth]);
        const phrase = viewState.allAyats[index]

        // menu start
        const [contextMenu, setContextMenu] = React.useState(null);

        const handleContextMenu = (event) => {

            event.preventDefault();
            console.log(event);
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            );
        };
        const handleClose = () => {
            setContextMenu(null);
        };

        // const [highlightedText, setHighlightedText] = React.useState(
        //     'highlighted text will be shown here!'
        //   );

        //   React.useEffect(() => {
        //     const saveSelection = () => {
        //         var selectedText = window.getSelection().toString()

        //       setHighlightedText(selectedText);
        //       if(selectedText != "")
        //       {
        //         var x = window.getSelection().getRangeAt(0).getBoundingClientRect().x;
        //         var y = window.getSelection().getRangeAt(0).getBoundingClientRect().y;
        //         handleContextMenu(x,y);
        //       }

        //     };

        //     document.addEventListener('mouseup', saveSelection);
        //     return () => document.removeEventListener('mouseup', saveSelection);
        //   }, []);

        const onClickPhraseItem = (text) => {
            console.log(text);
            textToCopy = text;
        }

        const onClickPopupIcon = (e) => {
            console.log(e);
            selectedPhraseId = 0
            selectedPhraseId = phrase.ayatId
            selectedPhrase = phrase.arabicText
            if (e == "Notes" || e == "Commentary") {
                setCol(3)
                handleBottomNavChange(e, e)
            }
            else if (e == "CopyAll") {
                const phraseToCopy = viewState.allAyats.find(item => item.ayatId === phrase.ayatId)
                //    console.log(phraseToCopy)
                const regex = /<[^>]*>/g;


                const transli = isTranslationOn ? phraseToCopy["translation"].replace(regex, '') + "\n" : ''
                const tran = isTransiliterationOn ? phraseToCopy["transiliteration"].replace(regex, '') + "\n" : ''
                const arb = isArabicOn ? phraseToCopy["arabicText"].replace(regex, '') + "\n" : ''

                navigator.clipboard.writeText(arb + tran + transli)
            }
            else {
                const regex = /<[^>]*>/g;
                navigator.clipboard.writeText(textToCopy.replace(regex, ''))
            }
            handleClose()

        };

        const onClickTag = (tag) => {
            handleIsUserLoginCheck()
            console.log(tag, phrase.ayatId);
            handleClose()

            const formData = new FormData();
            formData.append('label', tag)
            formData.append('user_id', localStorage.getItem("token"))
            formData.append('ayat_number', phrase.ayatId)

            addTag(formData).then((result) => {

                getAllTagsFromServer()

            }).catch(console.error.bind(console))
        };


        return (<div >

            {index == 0 ?
                (<Grid item xs={12} sm={12} md={12}
                    align="center">
                    <Typography variant='title' align="center">
                        {chapter.title}
                    </Typography>
                    <br />
                    <Typography variant='titleArabic' align="center" style={{ fontFamily: fontFamily }}>
                        {chapter.arabicTitle}
                    </Typography><br />
                    <Typography variant='subTopic' align="center" style={{ fontFamily: fontFamily }}>
                        {chapter.translation}
                    </Typography>
                </Grid>) : <></>}
            <Grid item xs={12} sm={12} md={12}
                align="center">
                <Grid item xs={12} sm={12} md={12}
                    align="center" m="5">

                    {phrase.topicTitle && <> <br /> <br /> <br /><br /></>}
                    <Typography variant='topic' align="center">
                        {phrase.topicTitle}
                    </Typography>

                    {phrase.subTopicTitle && <br />}

                    {phrase.subTopicTitle && !phrase.topicTitle && <>   <br /> <br /> <br />   <br /></>}
                    <Typography variant='subTopic' align="center">
                        {phrase.subTopicTitle}
                    </Typography>
                    <br />
                    {
                        phrase.context !== '' &&
                        <Box px={2} >
                            <Typography variant={'contextText'} style={{ fontSize: fontSize }} align="left" dangerouslySetInnerHTML={{
                                __html: phrase.context
                            }} />
                            <br />
                        </Box>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} key={index} align="center" px={5} py={1}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} align="left" onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }} >
                    {(isTransiliterationOn || isTranslationOn) ?
                        <Grid container xs={12} sm={12} md={(isArabicOn) ? 6 : 12} order={{ xs: 2, sm: 2, md: 1 }} align="left" >
                            {isTransiliterationOn ?
                                <Grid item xs={12} sm={12} md={(isArabicOn || !isTranslationOn) ? 12 : 6} align="left" >
                                    {isTransiliterationOn ?
                                        <>
                                            <Typography variant='transiliteration' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                                __html: ((phrase.ayatBreakPoint == "START" || phrase.ayatBreakPoint == "TOTAL") ? "<span class='ayahNumberEng'>" + phrase.ayatNumber + ". </span>" : "")

                                            }} >
                                            </Typography>
                                            <sup>
                                                {getBookMarkTag(phrase.ayatId)}
                                            </sup>
                                            <Typography variant='transiliteration' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                                __html: transiliteration1(phrase.transiliteration, index)

                                            }} onContextMenu={() => onClickPhraseItem(phrase.transiliteration)}>
                                            </Typography>
                                        </> : null}
                                </Grid>
                                : null}

                            {isTranslationOn ?
                                <Grid item xs={12} sm={12} md={(isArabicOn || !isTransiliterationOn) ? 12 : 6} align="left" >
                                    <Typography variant='translation' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                        __html: ((phrase.ayatBreakPoint == "START" || phrase.ayatBreakPoint == "TOTAL") ? (isTransiliterationOn ? "" : ("<span class='ayahNumberEng'>" + phrase.ayatNumber + ". </span>")) : "") + translation1(phrase.translation, index)

                                    }} onContextMenu={() => onClickPhraseItem(phrase.translation)}  >
                                    </Typography>
                                    {ayatRefrence(phrase, index)}
                                </Grid> : null}

                        </Grid> : null}

                    {isArabicOn ?
                        <Grid item xs={12} sm={12} md={(isTransiliterationOn || isTranslationOn) ? 6 : 12} order={{ xs: 1, sm: 1, md: 2 }} align="right" dir="rtl">
                            <Typography variant='arabic' style={{ fontSize: arabicFontSize, fontFamily: fontFamily }}
                                dangerouslySetInnerHTML={{
                                    __html: arabicText1(phrase.arabicText, index) + ((phrase.ayatBreakPoint == "END" || phrase.ayatBreakPoint == "TOTAL") ? " <span class='ayahNumber' style ='margin:7px'>" + Number(phrase.ayatNumber).toLocaleString('ar-u-nu-arab') + "</span> " : "")
                                }}
                                onContextMenu={() => onClickPhraseItem(phrase.arabicText)}   >
                            </Typography>
                        </Grid> : null}
                </Grid>
                <Divider />
            </Grid>

            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={() => onClickPopupIcon("Copy")}><CopyAllOutlined /> &nbsp;Copy</MenuItem>
                <MenuItem onClick={() => onClickPopupIcon("CopyAll")}><CopyAllOutlined /> &nbsp;Copy All</MenuItem>
                <MenuItem onClick={() => onClickPopupIcon("Notes")}><NotesOutlined /> &nbsp;Notes</MenuItem>
                {/* <MenuItem onClick={() => onClickPopupIcon("Commentary")}><CommentOutlined /> &nbsp;Commentary</MenuItem> */}
                <Divider />
                <Grid item xs={12} sm={12} md={12} align="center" >
                    <img src={bRed} width={25} height={10} p={5} onClick={() => onClickTag("Red")} style={{ cursor: "pointer" }} />
                    <img src={bBlue} width={25} height={10} p={5} onClick={() => onClickTag("Blue")} style={{ cursor: "pointer" }} />
                    <img src={bGold} width={25} height={10} p={5} onClick={() => onClickTag("Gold")} style={{ cursor: "pointer" }} />
                </Grid>

            </Menu>
        </div>)
    }


    function GroupdViewRow({ index, windowWidth, handleBottomNavChange }) {
        const ayat = uniqueGroupsArray[index]
        parseGroupListJS(ayat.groupNumber)

        const rowRef = React.useRef();


        React.useEffect(() => {
            setSize(index, rowRef.current.getBoundingClientRect().height);
        }, [setSize, index, windowWidth]);

        // menu start
        const [contextMenu, setContextMenu] = React.useState(null);
        const [ayatId, setAyatId] = React.useState(0);

        const handleContextMenu = (event, ayatId, text) => {
            event.preventDefault();
            console.log(ayatId);
            setAyatId(ayatId)
            textToCopy = text
            selectedPhrase = text
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            );
        };

        const handleClose = () => {
            setContextMenu(null);
        };

        const onClickPopupIcon = (e, ayatNumber) => {
            console.log(e);
            selectedPhraseId = 0
            selectedPhraseId = ayatNumber

            if (e == "Notes" || e == "Commentary") {
                setCol(3)
                handleBottomNavChange(e, e)
            }
            else if (e == "CopyAll") {
                const phraseToCopy = viewState.allAyats.find(item => item.ayatId === ayatNumber)
                //    console.log(phraseToCopy)
                const regex = /<[^>]*>/g;



                const transli = isTranslationOn ? phraseToCopy["translation"].replace(regex, '') + "\n" : ''
                const tran = isTransiliterationOn ? phraseToCopy["transiliteration"].replace(regex, '') + "\n" : ''
                const arb = isArabicOn ? phraseToCopy["arabicText"].replace(regex, '') + "\n" : ''

                navigator.clipboard.writeText(arb + tran + transli)
            }
            else {
                const regex = /<[^>]*>/g;
                navigator.clipboard.writeText(textToCopy.replace(regex, ''))
            }
            handleClose()

        };


        const onClickTag = (tag, ayatNumber) => {
            handleIsUserLoginCheck()
            console.log(tag, ayatNumber);
            handleClose()

            const formData = new FormData();
            formData.append('label', tag)
            formData.append('user_id', localStorage.getItem("token"))
            formData.append('ayat_number', ayatNumber)

            addTag(formData).then((result) => {
                getAllTagsFromServer()

            }).catch(console.error.bind(console))
        };


        return (<div ref={rowRef} style={style}>
            {index == 0 ?
                (<Grid item xs={12} sm={12} md={12} align="center">
                    <Typography variant='title' align="center">
                        {chapter.title}
                    </Typography>
                    <br />
                    <Typography variant='titleArabic' align="center" style={{ fontFamily: fontFamily }}>
                        {chapter.arabicTitle}
                    </Typography><br />
                    <Typography variant='subTopic' align="center" style={{ fontFamily: fontFamily }}>
                        {chapter.translation}
                    </Typography>
                </Grid>) : <></>}
            <Grid item xs={12} sm={12} md={12}
                align="center">
                <Grid item xs={12} sm={12} md={12}
                    align="center" pt="5">
                    {ayat.topicTitle && <> <br /> <br /> <br /><br /></>}
                    <Typography variant='topic' align="center">
                        {ayat.topicTitle}
                    </Typography>
                    {ayat.subTopicTitle && <br />}
                    {ayat.subTopicTitle && !ayat.topicTitle && <> <br /> <br /> <br /><br /></>}
                    <Typography variant='subTopic' align="center">
                        {ayat.subTopicTitle}
                    </Typography>
                    <br />
                    {
                        ayat.context !== '' &&
                        <Box px={2} >
                            <Typography variant={'contextText'} style={{ fontSize: fontSize }} align="left" dangerouslySetInnerHTML={{
                                __html: ayat.context
                            }} />
                            <br />
                        </Box>
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} order={{ xs: 2, sm: 2, md: 1 }} align="center" px={5} py={3}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} align="left" style={{ cursor: 'context-menu' }} >
                    {(isTransiliterationOn || isTranslationOn) ?
                        <Grid container xs={12} sm={12} md={(isArabicOn) ? 6 : 12} order={{ xs: 2, sm: 2, md: 1 }} align="left">
                            {isTransiliterationOn ?
                                <Grid item xs={12} sm={12} md={(isArabicOn || !isTranslationOn) ? 12 : 6} align="left" >
                                    {transiliterationGroupArray[index]?.map((item, index) => (
                                        <>
                                            <sup>
                                                {item.text && getBookMarkTag(item.ayatId)}
                                            </sup>
                                            <Typography variant='transiliteration' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                                __html: item.text

                                            }} onContextMenu={(event) => handleContextMenu(event, item.ayatId, item.text)}>
                                            </Typography>

                                        </>
                                    ))}
                                </Grid>
                                : null}
                            {isTranslationOn ?
                                <Grid item xs={12} sm={12} md={(isArabicOn || !isTransiliterationOn) ? 12 : 6} align="left">
                                    {translationGroupArray[index]?.map((item, _index) => (
                                        <>
                                            <Typography variant='translation' style={{ fontSize: fontSize }} dangerouslySetInnerHTML={{
                                                __html: item.text
                                            }} onContextMenu={(event) => handleContextMenu(event, item.ayatId, item.text)}></Typography>
                                            {ayatRefrence(item, index)}
                                        </>

                                    ))}
                                </Grid> : null}

                        </Grid> : null}
                    {isArabicOn ?
                        <Grid item xs={12} sm={12} md={(isTransiliterationOn || isTranslationOn) ? 6 : 12} order={{ xs: 1, sm: 1, md: 2 }} align="right" dir="rtl">
                            {arabicTextGroupArray[index]?.map((item, index) => (
                                <Typography variant='arabic' style={{ fontSize: arabicFontSize, fontFamily: fontFamily }} dangerouslySetInnerHTML={{
                                    __html: item.text

                                }} onContextMenu={(event) => handleContextMenu(event, item.ayatId, item.text)} sx={{ marginLeft: 1 }}></Typography>
                            ))}
                        </Grid> : null}
                </Grid>
                <Divider />
            </Grid>


            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={() => onClickPopupIcon("Copy", ayatId)}><CopyAllOutlined /> &nbsp;Copy</MenuItem>
                <MenuItem onClick={() => onClickPopupIcon("CopyAll", ayatId)}><CopyAllOutlined /> &nbsp;CopyAll</MenuItem>
                <MenuItem onClick={() => onClickPopupIcon("Notes", ayatId)}><NotesOutlined /> &nbsp;Notes</MenuItem>
                {/* <MenuItem onClick={() => onClickPopupIcon("Commentary")}><CommentOutlined /> &nbsp;Commentary</MenuItem> */}
                <Divider />
                <Grid item xs={12} sm={12} md={12} align="center" >
                    <img src={bRed} width={25} height={10} p={5} onClick={() => onClickTag("Red", ayatId)} style={{ cursor: "pointer" }} />
                    <img src={bBlue} width={25} height={10} p={5} onClick={() => onClickTag("Blue", ayatId)} style={{ cursor: "pointer" }} />
                    <img src={bGold} width={25} height={10} p={5} onClick={() => onClickTag("Gold", ayatId)} style={{ cursor: "pointer" }} />
                </Grid>

            </Menu>
        </div>)
    }
    // List view configs
    const listRef = React.useRef();

    // const scrollToIndexAndAlignTop = (index) => {
    //     if (listRef.current) {
    //         listRef.current.scrollToIndex({
    //           index,
    //           align: 'start',
    //         });
    //       }
    //   };

    React.useEffect(() => {
        if (listRef.current && scrollToIndex >= 0) {
            setTimeout(() => {
                listRef.current?.scrollToRow(scrollToIndex);
            }, 100);
        }
    }, [scrollToIndex]);


    const sizeMap = React.useRef({});
    const setSize = React.useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };

    }, []);
    const getSize = index => sizeMap.current[index] || 0;
    const [windowWidth, windowHeight] = useWindowResize();

    const [bottomNavValue, setBottomNavValue] = React.useState('Read');

    const handleBottomNavChange = (event, newValue) => {
        var element = document.getElementById(newValue == "Read" ? "Read" : "Notes");
        if (bottomNavValue == newValue && newValue == "Notes") {
            element = document.getElementById("Read");
            setBottomNavValue("Read");
        }
        else
            if (bottomNavValue == newValue && newValue == "Commentary") {
                element = document.getElementById("Read");
                setBottomNavValue("Read");
            }
            else {
                setBottomNavValue(newValue);
            }

        //alert(newValue);

        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ block: "start", behavior: "smooth" });

        }
    };

    // view state changed
    React.useEffect(() => {

        try {

            if (viewState.typeOfView === "Ayat") {
                setScrollToIndex(selectedAyatNumber)

            }
            else if (viewState.typeOfView === "Phrase") {
                const _selectedAyatNumber = viewState.allAyats.findIndex(item => item.ayatNumber == selectedAyatNumber);
                setScrollToIndex(_selectedAyatNumber - 1)
                setScrollToIndex(_selectedAyatNumber)

            }
            else {
                const _selectedAyatNumber = uniqueGroupsArray.findIndex(item => item.ayatNumber == selectedAyatNumber);
                setScrollToIndex(_selectedAyatNumber - 1)
                setScrollToIndex(_selectedAyatNumber)

            }


            console.log("scrollTo", scrollToIndex);
        }
        catch (err) {
            // Block of code to handle errors
        }


    }, [selectedAyatNumber, viewType]);

    const boxStyles = {
        paddingBottom: 40,
        scrollbarWidth: 'thin', // For Firefox
        scrollbarColor: '#a0a0a0 transparent', // For Firefox
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#a0a0a0',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a0a0a0',
        },
    };

    const handleNextChapter = () => {
        // Find the index of the current selected chapter
        const currentIndex = chapters.findIndex(ch => ch.id === chapter.id);

        // Check if there's a next chapter
        if (currentIndex < chapters.length - 1) {
            setSelectedChapter(chapters[currentIndex + 1]);
        }
    };

    return (
        <Box sx={{ pt: (windowWidth > 1000 ? "100px" : "120px") }} >
            <Box  >
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">

                    <Box sx={style.modalContainer} >
                        <Box align="right">
                            <IconButton onClick={handleClose} >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <p id="modal-modal-description" sx={{ mt: 2 }} p={2} dangerouslySetInnerHTML={{ __html: modexText }}>
                        </p>

                    </Box>

                </Modal>

                <Grid container xs={12} sm={12} md={12} position="relative">
                    <Grid item xs={12} sm={12} md={col > 2 ? 8 : 12}>
                        {checked.isLoading ?
                            <Box
                                id="mainBoxChapter"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                pt={5}
                            >
                                <CircularProgress color="text" />
                            </Box> : null}

                        {checked.show ?
                            <div >
                                <Grid item xs={12} sm={12} md={12} align="right" style={{ paddingRight: 20 }}>
                                    <img src={bRed} width={20} height={10} onClick={() => handleTags("RED")} style={{ cursor: "pointer" }} />
                                    <img src={bBlue} width={20} height={10} onClick={() => handleTags("BLUE")} style={{ cursor: "pointer" }} />
                                    <img src={bGold} width={20} height={10} onClick={() => handleTags("GOLD")} style={{ cursor: "pointer" }} />
                                </Grid>
                                <div style={{ height: windowWidth > 1000 ? "calc(100vh - 130px)" : "calc(100vh - 210px)" }}>


                                    <AutoSizer>
                                        {({ width, height }) => (
                                            <List
                                                ref={listRef}
                                                style={boxStyles}
                                                width={width}
                                                height={height}
                                                // scrollToAlignment="start"
                                                rowCount={
                                                    viewState.typeOfView === "Ayat"
                                                        ? uniqueAyatsArray.length
                                                        : viewState.typeOfView === "Phrase"
                                                            ? viewState.allAyats.length
                                                            : uniqueGroupsArray.length
                                                }
                                                deferredMeasurementCache={cache}
                                                rowHeight={cache.rowHeight}
                                                overscanCount={300} // Adjust overscan count as needed
                                                scrollToIndex={Math.max(0, scrollToIndex - 1)}
                                                onRowsRendered={({ startIndex, stopIndex }) => {
                                                    listRef.current && listRef.current.forceUpdateGrid();

                                                    // If `scrollToIndex` is outside of rendered range, re-render
                                                    // if ((scrollToIndex < startIndex || scrollToIndex > stopIndex) && scrollFlag) {
                                                    //     setScrollFlag(false); // Reset the flag 
                                                    //     listRef.current && listRef.current.scrollToRow(scrollToIndex - 1);
                                                    // }
                                                }}
                                                rowRenderer={({ index, key, style, parent }) => (
                                                    <CellMeasurer
                                                        key={key}
                                                        cache={cache}
                                                        parent={parent}
                                                        columnIndex={0}
                                                        rowIndex={index}
                                                    >
                                                        {({ registerChild }) => (
                                                            <div style={style} ref={registerChild}>
                                                                {viewState.typeOfView === "Ayat" && uniqueAyatsArray.length > 0 && (
                                                                    <AyatViewRow
                                                                        key={index} // Ensure each item has a stable key
                                                                        index={index}
                                                                        windowWidth={windowWidth}
                                                                        handleBottomNavChange={handleBottomNavChange}
                                                                    />
                                                                )}
                                                                {viewState.typeOfView === "Phrase" && (
                                                                    <PhraseViewRow
                                                                        key={index} // Ensure each item has a stable key
                                                                        index={index}
                                                                        windowWidth={windowWidth}
                                                                        handleBottomNavChange={handleBottomNavChange}
                                                                    />
                                                                )}
                                                                {viewState.typeOfView !== "Ayat" &&
                                                                    viewState.typeOfView !== "Phrase" &&
                                                                    uniqueGroupsArray.length > 0 && (
                                                                        <GroupdViewRow
                                                                            key={index} // Ensure each item has a stable key
                                                                            index={index}
                                                                            windowWidth={windowWidth}
                                                                            handleBottomNavChange={handleBottomNavChange}
                                                                        />
                                                                    )}

                                                                {/* Render "Next Chapter" button at the end */}
                                                                {index >=
                                                                    (viewState.typeOfView === "Ayat"
                                                                        ? uniqueAyatsArray.length
                                                                        : viewState.typeOfView === "Phrase"
                                                                            ? viewState.allAyats.length
                                                                            : uniqueGroupsArray.length) -
                                                                    1 &&
                                                                    chapter.id !== "121" && (
                                                                        <Box display="flex" justifyContent="flex-end">
                                                                            <Button
                                                                                variant="contained"
                                                                                size="small"
                                                                                style={{ color: mode === "dark" ? "#fff" : "" }}
                                                                                onClick={handleNextChapter}
                                                                            >
                                                                                <b>Next Chapter </b>
                                                                                <ArrowForward />
                                                                            </Button>
                                                                        </Box>
                                                                    )}
                                                            </div>
                                                        )}
                                                    </CellMeasurer>
                                                )}
                                            />
                                        )}
                                    </AutoSizer>


                                </div>
                            </div>
                            : null}


                    </Grid>
                    {(windowWidth < 1000 || col == 3) &&
                        <Grid item xs={12} sm={12} md={4} sx={{ borderLeft: 0.5, borderColor: mode == "dark" ? '#000' : '#D1D0D0' }}>
                            <Box >
                                <div class="notes">
                                    {windowWidth > 1000 &&
                                        <Box style={{ height: windowHeight - 100, overflow: 'auto' }} >
                                            <Notes selectedPhraseId={selectedPhraseId} selectedPhrase={selectedPhrase} setCol={setCol} mode={mode} bottomNavValue={bottomNavValue} handleBottomNavChange={handleBottomNavChange} handleIsUserLoginCheck={handleIsUserLoginCheck} setSelectedChapter={setSelectedChapter} setSelectedAyatNumber={setSelectedAyatNumber} setViewType={setViewType} />

                                        </Box>
                                    }
                                </div>
                            </Box>
                        </Grid>
                    }
                </Grid>

                {windowWidth < 1000 &&

                    bottomNavValue == "Notes" &&
                    <Box
                        position="absolute"
                        top={150}

                        width={'100%'}
                        height={windowHeight}
                        overflow={'auto'}
                        bgcolor="body.main" // Blue with 50% opacity
                    >
                        <Notes selectedPhraseId={selectedPhraseId} selectedPhrase={selectedPhrase} setCol={setCol} mode={mode} bottomNavValue={bottomNavValue} handleBottomNavChange={handleBottomNavChange} handleIsUserLoginCheck={handleIsUserLoginCheck} setSelectedChapter={setSelectedChapter} setSelectedAyatNumber={setSelectedAyatNumber} setViewType={setViewType} />
                    </Box>
                }



            </Box>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: "block", sm: "block", md: "none" }, zIndex: 3 }} elevation={3} >
                <BottomNavigation
                    onChange={handleBottomNavChange}
                    showLabels
                    value={bottomNavValue}
                    sx={{
                        bgcolor: 'bottomNav.bg',
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: theme => theme.typography.caption,
                            transition: 'none',
                            lineHeight: '20px'
                        },
                        '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                            color: 'text.main'
                        },
                        '& .Mui-selected': {
                            '& .MuiBottomNavigationAction-label': {
                                fontSize: theme => theme.typography.caption,
                                transition: 'none',
                                fontWeight: 'bold',
                                lineHeight: '20px'
                            },
                            '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                                color: 'text.main'
                            },

                        }
                    }}
                >
                    <BottomNavigationAction value="Read" label="Read" icon={<AutoStories />} />
                    {/* <BottomNavigationAction value="Commentary" label="Commentary" icon={<TextSnippet />} /> */}
                    <BottomNavigationAction value="Notes" label="Notes" icon={<TextSnippet />} />
                </BottomNavigation>
            </Paper>
        </Box>
    )
}

export default React.memo(Chapter)

export const useWindowResize = () => {
    const [size, setSize] = React.useState([0, 0]);

    React.useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
};