import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import headerBackground from "./assets/images/header-wht.jpg";
import headerBackgroundBlack from "./assets/images/header-blk.jpg";
import headerBackgroundSepia from "./assets/images/header-spc.jpg";
import logo from "./assets/logo.png";
import menuIcon from "./assets/images/menu-b.png";
import menuIconLight from "./assets/images/menu-w.png";
import settingsIconBlack from "./assets/images/settings-b.png";
import settingsIcon from "./assets/images/settings-w.png";
import oneColIcon from "./assets/images/one-col.png";
import twoColIcon from "./assets/images/two-col.png";
import threeColIcon from "./assets/images/three-col.png";
import oneColIconWhite from "./assets/images/one-col-w.png";
import twoColIconWhite from "./assets/images/two-col-w.png";
import threeColIconWhite from "./assets/images/three-col-w.png";
import splitScreenIcon from "./assets/images/split-screen-w.png";
import splitScreenIconBlack from "./assets/images/split-screen.png";
import userIconBlack from "./assets/images/user-b.png";
import userIcon from "./assets/images/user-w.png";
import {
  Autocomplete,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  Menu,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  Slide,
  Slider,
  SwipeableDrawer,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  collapseClasses,
  createFilterOptions,
  useScrollTrigger
} from '@mui/material';
import Chapter, { useWindowResize } from './Chapter';
import { ArrowBack, ArrowDownward, AutoStories, Book, ChevronLeft, ChevronRight, Close, Delete, EditNote, EditNoteOutlined, Expand, ExpandMore, Home, MenuOpen, MusicNote, Search, SearchOffOutlined, SearchOutlined, SearchRounded, Send, SouthRounded } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import { getAllTags, getChapters, getSuggestions } from './Api';
import { Button } from 'react-bootstrap';
import { Main } from './Main';
import Indexes from './Indexes';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Register from './Register';
import Tag from './Tag';
import Glossary from './Glossary';
import styled from '@emotion/styled';
import SignInDialog from './User/Register';
import { UserContext } from './context/UserContext';
import AllNotes from './AllNotes';
import { useTheme } from '@emotion/react';
import { forwardRef, useImperativeHandle } from "react";

const drawerWidth = 270;
var modexText = ""
const style = {
  listContainer: {
    height: "100vh",
    width: "100%"
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "90%", sm: "90%", md: 400 },
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '25px'
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



function App({ props, setMode, mode }) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname)
  const { logout, loginUser, wait, loggedInCheck, registerUser } = React.useContext(UserContext);
  const scrollTrigger = useScrollTrigger();
  const window = props;
  const trigger = useScrollTrigger();
  const [col, setCol] = React.useState(1);
  const [suggestions, setSuggestions] = React.useState([
    {
      "id": "7",
      "word": "Mecca",
      "otherwords": "Makkah,Macca"
    }, {
      "id": "8",
      "word": "Mecca",
      "otherwords": "Makkah,Macca"
    }
  ]);
  const [chapters, setChapters] = React.useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedChapter, setSelectedChapter] = React.useState({});
  const [selectedAyatNumber, setSelectedAyatNumber] = React.useState(1);

  const [righDrawerOpen, setRighDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElWindowSettings, setAnchorElWindowSettings] = React.useState(null);
  const [onClickTag, setOnClickTag] = React.useState(null);
  const [contentType, setContentType] = React.useState("home");

  const leftDrawerRef = React.useRef();

  const handleDrawerToggle = React.useCallback(() => {
    if (leftDrawerRef.current) {
      leftDrawerRef.current.resetSearchFields();
    }
    setMobileOpen((prev) => !prev);
  }, []);


  const path = location.pathname;
  var splitUrlReadData = []
  const extractedData = path?.split('/')[2]; // Get the part after the second slash
  splitUrlReadData = extractedData?.split(':'); // Split "3:18" into ["3", "18"]



  function onClickChapter(chapter) {
    //setMobileOpen(!mobileOpen);

    setTimeout(function () { //Start the timer
      setSelectedChapter(chapter)
      setSelectedAyatNumber(1)
      //setSearchTerm("")
      //ayatNumbersRef.current.scrollTo(0, 0);
    }.bind(this), 100)

  }


  function onClickAyatNumber(number) {
    setMobileOpen(!mobileOpen);

    setTimeout(function () { //Start the timer
      setSelectedAyatNumber(number)
    }.bind(this), 100)

  }


  React.useEffect(() => {
    getSuggestions().then((result) => {
      setSuggestions(result.words)

    }).catch(console.error.bind(console))

  }, []);

  const filterOptions = createFilterOptions({
    stringify: ({ id, word, otherwords }) => `${id}${word} ${otherwords}`
  });

  const _selectedChapter = localStorage.getItem("selectedChapter")
  const _selectedAyatNumber = localStorage.getItem("selectedAyatNumber")
  React.useEffect(() => {
    getChapters().then((result) => {
      setChapters(result.chapters)
      if (splitUrlReadData && splitUrlReadData.length > 0) {

        var chap = splitUrlReadData[0] - 1
        console.log("chap", chap)
        setSelectedChapter(result.chapters[chap])
        setSelectedAyatNumber(splitUrlReadData[1])
      }
      else
        if (_selectedChapter === null) {
          setSelectedChapter(result.chapters[0])
          console.log("_selectedChapter", "null")

        }
        else {
          console.log(JSON.parse(_selectedChapter))
          setSelectedChapter(JSON.parse(_selectedChapter))
          setSelectedAyatNumber(_selectedAyatNumber)
          console.log("_selectedChapter", "null2")
        }
    }).catch(console.error.bind(console))

  }, []);

  const [searchText, setSearchText] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const onChangeSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    if (searchTerm?.length > 2) {
      console.log(searchTerm)
      setSearchText(searchTerm.trim())
      navMenuItemClick("indexes")
      setTimeout(() => {
        handleCloseSearchDialog()
      }, 1000);
    }
  };

  const handleSearch2 = (e) => {

    setSearchText(e)
    navMenuItemClick("indexes")
    handleCloseSearchDialog()

  };


  const handleProfile = () => {
    setContentType("register");
    navigate("/register")
    setOpenSuggestions(false);

  };


  const handleTags = (e) => {

    const isLoggedIn = loggedInCheck();
    if (!isLoggedIn) {
      setOpenSignInDialog(true);
    }
    else {
      setOnClickTag(e);
      setContentType("tags");
      navigate("/tags");
      setOpenSuggestions(false);
    }


  };



  const ayatNumbersRef = React.useRef(null);
  var ayatArray = []

  try {
    ayatArray = [...Array(parseInt(selectedChapter.totalAyats))]

  } catch {

  }



  const isArabicOnItem = localStorage.getItem("isArabicOn")
  const isTranslationOnItem = localStorage.getItem("isTranslationOn")
  const isTransiliterationOnItem = localStorage.getItem("isTransiliterationOn")
  const [isArabicOn, setIsArabicOn] = React.useState(isArabicOnItem === null ? true : (isArabicOnItem === 'true'));
  const [isTranslationOn, setIsTranslationOn] = React.useState(isTranslationOnItem === null ? true : (isTranslationOnItem === 'true'));
  const [isTransiliterationOn, setIsTransiliterationOn] = React.useState(isTransiliterationOnItem === null ? false : (isTransiliterationOnItem === 'true'));

  const handleTranlstaionChange = () => {
    localStorage.setItem("isTranslationOn", !isTranslationOn);
    setIsTranslationOn(!isTranslationOn);

  }

  const handleTransiliteration = () => {
    localStorage.setItem("isTransiliterationOn", !isTransiliterationOn);
    setIsTransiliterationOn(!isTransiliterationOn);
  }

  const handleArabic = () => {
    localStorage.setItem("isArabicOn", !isArabicOn);
    setIsArabicOn(!isArabicOn);
  }

  const isSyncFontSizeItem = localStorage.getItem("syncFontSize")
  const [isSyncFontSize, setSyncFontSize] = React.useState(isSyncFontSizeItem === null ? true : (isSyncFontSizeItem === 'false'));
  const handleSyncFontSize = () => {
    localStorage.setItem('syncFontSize', !isSyncFontSize);
    setSyncFontSize(!isSyncFontSize)
    if (!isSyncFontSize) {
      const max = arabicFontSize > fontSize ? arabicFontSize : fontSize;
      setArabicFontSize(max)
      setFontSize(max)
      localStorage.setItem('fontSize', max);
      localStorage.setItem('arabicFontSize', max);
      setSelectedAyatNumber(selectedAyatNumber)
    }
  };



  function synFontSizes(fontSize) {
    if (isSyncFontSize) {
      setArabicFontSize(fontSize)
      setFontSize(fontSize)
      localStorage.setItem('fontSize', fontSize);
      localStorage.setItem('arabicFontSize', fontSize);
      setSelectedAyatNumber(selectedAyatNumber)
    }
  }

  const [viewType, setViewType] = React.useState(() => {
    return localStorage.getItem("viewType") ?? "Ayat"
  });

  const handleViewChange = (event, newView) => {
    if (newView != null) {
      localStorage.setItem("viewType", newView);
      setViewType(newView)


    }
  };

  const [onSlide, setOnSlide] = React.useState(false);
  const onTouchEnded = () => {
    setOnSlide(false)
  }

  const [fontSize, setFontSize] = React.useState(() => {
    return localStorage.getItem("fontSize") ?? 18
  });


  const handleFontSizeChange = (event) => {
    localStorage.setItem('fontSize', event.target.value);
    setFontSize(event.target.value);
    synFontSizes(event.target.value);
    setSelectedAyatNumber(selectedAyatNumber)
    setOnSlide(true)
  };

  const [arabicFontSize, setArabicFontSize] = React.useState(() => {
    return localStorage.getItem("arabicFontSize") ?? 18
  });

  const _fontFamily = localStorage.getItem("fontFamily")

  const [fontFamily, setFontFamily] = React.useState(_fontFamily === null ? "Default" : _fontFamily)
  const handleFontTypeChange = (event, newView) => {
    if (newView != null) {
      localStorage.setItem('fontFamily', newView);
      setFontFamily(newView)
    }
  };

  const handleArabicFontSizeChange = (event) => {

    localStorage.setItem('arabicFontSize', event.target.value);
    setArabicFontSize(event.target.value);
    synFontSizes(event.target.value);
    setOnSlide(true)
  };


  React.useEffect(() => {

    setFontSize(Number(localStorage.getItem("fontSize") ?? "16"));
    setArabicFontSize(Number(localStorage.getItem("arabicFontSize") ?? "24"))
    setFontFamily(localStorage.getItem("fontFamily") ?? "Default")

  }, []);


  const container = window !== undefined ? () => window().document.body : undefined;
  const [windowWidth, windowHeight] = useWindowResize();
  const openMenu = Boolean(anchorEl);
  const openWindowSettings = Boolean(anchorElWindowSettings);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickWindowSettings = (event) => {
    setAnchorElWindowSettings(event.currentTarget);
  };

  const handleClickWindowSettingsClose = () => {
    setAnchorElWindowSettings(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openLeft, setOpenLeft] = React.useState(false);


  function navMenuItemClick(type) {
    setAnchorEl(null)
    setOpenLeft(false);

    setContentType(type);
    navigate("/" + type);
    setOpenSuggestions(false);

  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenLeft(open);
  };


  const [openSearchDialog, setOpenSearchDialog] = React.useState(false);

  const handleClickOpenSearchDialog = () => {
    setOpenSearchDialog(true);
  };

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false);
  };


  const [openSignInDialog, setOpenSignInDialog] = React.useState(false);

  const handleIsUserLoginCheck = () => {
    const isLoggedIn = loggedInCheck();
    if (!isLoggedIn) {
      setOpenSignInDialog(true);
    }
  };


  const handleClickOpenSignInDialog = (event) => {
    const isLoggedIn = loggedInCheck();
    if (!isLoggedIn) {
      setOpenSignInDialog(true);
    }
    else {
      handleClickProfileMenu(event);
    }
  };

  const handleCloseSignInDialog = () => {
    setOpenSignInDialog(false);
    console.log(handleCloseSignInDialog)
  };


  const [anchorElProfileMenu, setAnchorElProfileMenu] = React.useState(null);
  const openProfileMenu = Boolean(anchorElProfileMenu);

  const handleClickProfileMenu = (event) => {

    setAnchorElProfileMenu(event.currentTarget);

  };
  const handleCloseProfileMenu = () => {
    setAnchorElProfileMenu(null);
  };

  const logoutUser = (event) => {
    logout()
    setAnchorElProfileMenu(null);
  };

  const [openSuggestions, setOpenSuggestions] = React.useState(false);

  const theme = useTheme();

  const inputStyle = {
    color: theme.palette.text.main, // Example: Accessing primary color from the theme
    borderColor: theme.palette.text.main, // Example: Accessing secondary color from the theme
    // Add any other styles as needed
    border: `0.5px solid ${theme.palette.text.main}`, // Example: Accessing secondary color from the theme
    borderRadius: theme.shape.borderRadius,
  };


  // model state
  const [open, setOpen] = React.useState(false);
  const handleOpenFootNote = (text) => {
    modexText = text
    setOpen(true);
    console.log("open")
  }
  const handleCloseFootNote = () => setOpen(false);

  return (
    <Box sx={{ display: 'flex' }}>


      <Modal
        open={open}
        onClose={handleCloseFootNote}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">

        <Box sx={style.modalContainer} >
          <Box align="right">
            <IconButton onClick={handleCloseFootNote} >
              <CloseIcon />
            </IconButton>
          </Box>

          <p id="modal-modal-description" sx={{ mt: 2 }} p={2} dangerouslySetInnerHTML={{ __html: modexText }}>
          </p>

        </Box>

      </Modal>

      <SignInDialog open={openSignInDialog} handleClose={handleCloseSignInDialog} />

      <Dialog
        open={openSearchDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseSearchDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={{ "& .MuiDialog-container": { alignItems: "flex-start", padding: '10px !important', }, "& .MuiDialog-root": { padding: '0px !important', } }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center', width: "100%", backgroundColor: "body.main", borderColor: "text.main" }}
        >
          <Autocomplete
            open={searchTerm?.length < 3 ? false : openSuggestions}
            sx={{ color: "text.main" }}

            onInputChange={(_, value) => {
              if (searchTerm?.length <= 2) {
                setOpenSuggestions(false);
              } else if (searchTerm) {
                setOpenSuggestions(true);
              }
              else {
                setOpenSuggestions(false);
              }
            }}
            onClose={() => setOpenSuggestions(false)}
            id="search-input2"
            onSelect={(event) => onChangeSearch(event)}
            onChange={(e, v) => setSearchTerm(v)}
            options={suggestions}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.word}
            onBlur={() => setOpenSuggestions(false)}
            filterSelectedOptions
            renderOption={(props, option) => (
              <li {...props}>
                {option.word}
              </li>
            )}

            renderInput={(params) => (
              <TextField
                sx={{ m: 1, flex: 1, color: "text.main", width: "290px", }}
                {...params}
                label=""
                margin="normal"
                variant="outlined"
                onChange={({ target }) => setSearchTerm(target.value)}

                onKeyDown={(ev) => {
                  console.log(`Pressed keyCode ${ev.key}`);
                  if (ev.key === 'Enter') {
                    // Do code here
                    handleSearch()

                  }
                }}

                InputProps={{
                  ...params.InputProps,
                  style: inputStyle,
                  startAdornment: (
                    <>
                      <Search sx={{ color: "text.main" }} />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

        </Box>
      </Dialog>

      <Slide appear={true} direction="down" in={!scrollTrigger}>
        <AppBar
          color="primary"
          position="fixed"
          elevation={0}

          style={{
            backgroundImage: `url(${mode == "dark" ? headerBackgroundBlack : (mode == "sepia" ? headerBackgroundSepia : headerBackground)})`,
            backgroundposition: "left",
            height: "80px"
          }}
        >
          <Box py={1}>
            <Toolbar >
              <Box display='flex' flexGrow={1} >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer(true)}
                  sx={{ mr: 2, display: { xs: "block", sm: "block", md: "none" } }}
                >
                  <img src={mode == "dark" ? menuIconLight : menuIcon} alt="menu-icon" style={{ width: 40 }} />
                </IconButton>

                <img src={logo} alt="logo" onClick={(event) => navMenuItemClick("home")} />
                <Typography variant="appBarTitle" p={1} onClick={(event) => navMenuItemClick("home")} sx={{ display: { xs: "none", sm: "block", md: "block" } }} >
                  &nbsp;&nbsp;&nbsp;Al - Maarif
                </Typography>

              </Box>
              <Typography onClick={(event) => navMenuItemClick("home")} variant={"menuItem"} sx={{ px: 2, display: { xs: "none", sm: "none", md: "block" } }}>Home</Typography>
              <Typography onClick={(event) => navMenuItemClick("read")} variant={"menuItem"} sx={{ px: 2, display: { xs: "none", sm: "none", md: "block" } }}>Read Quran</Typography>
              <Typography onClick={(event) => navMenuItemClick("glossary")} variant={"menuItem"} sx={{ px: 2, display: { xs: "none", sm: "none", md: "block" } }}>Indexes</Typography>


              {location.pathname.includes("/read") &&
                <>
                  <IconButton
                    onClick={() => { if (col == 3) { setCol(1) } else { setCol(3) } }}
                    sx={{ px: 2, display: { xs: "none", sm: "none", md: "block" } }}
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                  >
                    <EditNote style={{ fill: mode == "dark" ? '#fff' : '#000' }} fontSize="large" />
                    {/* <img src={mode == "dark" ? splitScreenIcon : splitScreenIconBlack} alt="split screen" style={{ width: 25 }} /> */}
                  </IconButton>
                  <IconButton
                    onClick={handleClick}
                    sx={{ px: 2 }}
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                  >
                    <img src={mode == "dark" ? settingsIcon : settingsIconBlack} alt="settings" style={{ width: 25 }} />
                  </IconButton></>
              }
              <Box
                sx={{ display: { md: 'flex', sm: 'none', xs: 'none' }, alignItems: 'center', width: 200 }}
              >
                <Autocomplete
                  open={searchTerm?.length < 3 ? false : openSuggestions}
                  onInputChange={(_, value) => {
                    if (searchTerm?.length < 2) {
                      setOpenSuggestions(false);
                    } else if (searchTerm) {
                      setOpenSuggestions(true);
                    }
                    else {
                      setOpenSuggestions(true);
                    }
                  }}
                  onClose={() => setOpenSuggestions(false)}
                  id="search-input"
                  onSelect={(event) => onChangeSearch(event)}
                  onChange={onChangeSearch}

                  options={suggestions}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.word}
                  filterSelectedOptions
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.word}
                    </li>
                  )}

                  renderInput={(params) => (
                    <TextField
                      sx={{ m: 1, flex: 1, width: "190px" }}

                      {...params}
                      label=""
                      margin="normal"
                      variant="outlined"
                      size="small"
                      onChange={onChangeSearch}
                      onKeyDown={(ev) => {
                        console.log(`Pressed keyCode ${ev.key}`);
                        if (ev.key === 'Enter') {
                          // Do code here
                          handleSearch()

                        }
                      }}

                      InputProps={{
                        ...params.InputProps,
                        style: inputStyle,
                        startAdornment: (
                          <>
                            <Search sx={{ color: "text.main" }} />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <IconButton color="text" sx={{ p: '10px', display: { md: 'none', sm: 'block', xs: 'block' } }} aria-label="search" onClick={(e) => (handleClickOpenSearchDialog())}>
                <SearchOutlined />
              </IconButton>
              <IconButton
                sx={{ px: 2 }}
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={(e) => (handleClickOpenSignInDialog(e))}
              >
                <img src={mode == "dark" ? userIcon : userIconBlack} alt="profile" style={{ width: 25 }} />
              </IconButton>

              <Menu
                id="basic-menu-profile"
                anchorEl={anchorElProfileMenu}
                open={openProfileMenu}
                onClose={handleCloseProfileMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={logoutUser}>Logout</MenuItem>
              </Menu>

            </Toolbar>

          </Box>
          {location.pathname.includes("/read") ?
            <>
              <Divider />
              <Box sx={{ backgroundColor: 'body.main', pt: 1, display: { xs: 'block', sm: (col > 1 ? 'none' : 'block') } }} >
                <IconButton
                  onClick={handleDrawerToggle}
                  size="small"
                  sx={{ px: 2 }}
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                >
                  <MenuOpen color="text" />
                  <Typography sx={{ color: "text.main" }}>{selectedChapter?.title}</Typography>

                </IconButton>

              </Box>
              <Divider />
            </> : null}

        </AppBar>
      </Slide>
      <SwipeableDrawer
        anchor="left"
        open={openLeft}
        PaperProps={{
          sx: {
            backgroundColor: "body.main",
            color: "text.main",
          }
        }}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ width: 300 }} >

          <Toolbar >
            <Box display='flex' flexGrow={1}>
              <Typography variant="appBarTitle" p={1}>
                <img src={logo} alt="logo" width="50px" /> Al- Maarif
              </Typography>
            </Box>
            <IconButton onClick={toggleDrawer(false)}>
              <Close style={{ fill: mode == "dark" ? '#fff' : '#000' }} />
            </IconButton>
          </Toolbar>
          <List>

            <ListItem disablePadding >
              <ListItemButton onClick={(event) => { navMenuItemClick("home") }} >
                <ListItemText primary={<Typography variant="listItem" >  Home</Typography>} />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding >
              <ListItemButton onClick={(event) => { navMenuItemClick("read") }} >
                <ListItemText primary={<Typography variant="listItem" > Read the Quran</Typography>} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={(event) => { navMenuItemClick("glossary") }} >
                <ListItemText primary={<Typography variant="listItem" >Indexes</Typography>} />
              </ListItemButton>
            </ListItem>
            <Divider />
            <Divider />
          </List>
        </Box>
      </SwipeableDrawer>

      {/* <Menu
        anchorEl={anchorElWindowSettings}
        open={openWindowSettings}
        id="window-settings-menu"
        onClose={handleClickWindowSettingsClose}

        PaperProps={{
          elevation: 1,
          sx: {
            backgroundColor: "body.main",
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'body.main',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ p: 2 }}>
          <div class="items">
            <ul class="color-swatches d-flex align-items-center justify-content-between" >
              <li><button><img onClick={(event) => setCol(1)} src={mode == "dark" ? oneColIconWhite : oneColIcon} width="60px" style={{ padding: 5 }} /></button></li>
              <li><button><img onClick={(event) => setCol(2)} src={mode == "dark" ? twoColIconWhite : twoColIcon} width="60px" style={{ padding: 5 }} /></button></li>
              <li><button><img onClick={(event) => setCol(3)} src={mode == "dark" ? threeColIconWhite : threeColIcon} width="60px" style={{ padding: 5 }} /></button></li>
            </ul>
            <ul class="color-swatches d-flex align-items-center justify-content-between" >
                <li style={{width: '60px', textAlign: 'center' }}>
                  <span style={{ display: 'block', textAlign: 'center', color: mode == "dark" ? "#FFF" : "#000" }}>Default</span>
              </li>
              <li style={{width: '60px', textAlign: 'center' }}>
                  <span style={{ display: 'block', textAlign: 'center',  color: mode == "dark" ? "#FFF" : "#000" }}>Chapters</span>
              </li>
               <li style={{width: '60px', textAlign: 'center' }}>
                   <span style={{ display: 'block', textAlign: 'center',  color: mode == "dark" ? "#FFF" : "#000" }}>Notes</span>
                </li>
            </ul>
          </div>
        </Box>
      </Menu> */}
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        id="settings-menu"
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            backgroundColor: "body.main",
            overflowY: "auto",
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "body.main",
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}

        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ p: 5 }} >
          <div class="items" style={{ display: windowWidth < 600 && onSlide ? "none" : "block" }}>
            <ul class="color-swatches d-flex align-items-center justify-content-between">
              <li><button type="button" class="white" onClick={(event) => setMode('light')} ><span class="white"></span><label style={{ color: mode == "dark" ? "#FFF" : "#000" }}>White</label></button></li>
              <li><button type="button" class="sepia" onClick={(event) => setMode('sepia')}><span class="sepia"></span><label style={{ color: mode == "dark" ? "#FFF" : "#000" }}>Sepia</label></button></li>
              <li><button type="button" class="black" onClick={(event) => setMode('dark')}><span class="black"></span><label style={{ color: mode == "dark" ? "#FFF" : "#000" }}>Black</label></button></li>
            </ul>
          </div>
          <Divider />
          <div class="items txt-setting" style={{ marginTop: 30, }}>
            <Box sx={{
              backgroundColor: "body.main"
            }}>
              <h6><Typography variant='body_title1'>Text Settings</Typography></h6>
              <div class="d-flex align-items-center">
                <div >
                  <Typography variant='default_body' style={{ width: "200px" }}>
                    English Font  Size: {fontSize}
                  </Typography>
                  <Slider
                    value={fontSize}
                    min={12}
                    max={50}
                    onChange={handleFontSizeChange}
                    onChangeCommitted={onTouchEnded}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                    sx={{
                      '& .MuiSlider-thumb': {
                        color: "slider.main"
                      },
                      '& .MuiSlider-track': {
                        color: "slider.main"
                      },
                      '& .MuiSlider-rail': {
                        color: "slider.main"
                      },
                      '& .MuiSlider-active': {
                        color: "slider.main"
                      }
                    }}
                  />
                </div >
              </div>

              <div class="d-flex align-items-center">
                {/* <div class="flex-grow-0" style={{ width: "100px" }}><Typography variant='default_body'>Arabic Font Size</Typography></div> */}
                <div >
                  <Typography variant='default_body' style={{ width: "200px" }}>
                    Arabic Font  Size: {arabicFontSize}
                  </Typography>

                  <Slider
                    value={arabicFontSize}
                    min={12}
                    max={50}
                    onChange={handleArabicFontSizeChange}
                    onChangeCommitted={onTouchEnded}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                    sx={{
                      '& .MuiSlider-thumb': {
                        color: "slider.main"
                      },
                      '& .MuiSlider-track': {
                        color: "slider.main"
                      },
                      '& .MuiSlider-rail': {
                        color: "slider.main"
                      },
                      '& .MuiSlider-active': {
                        color: "slider.main"
                      }
                    }}
                  />
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="flex-grow-0"><Typography variant='default_body'>Sync Font Sizes</Typography></div>
                <div class="flex-grow-1 d-flex"><div class="checkbox-wrapper"><div class="block"><input data-index="0" id="c1" type="checkbox" checked={isSyncFontSize}
                  onChange={handleSyncFontSize} /><label for="c1"></label></div></div></div>
              </div>
            </Box>
            <Divider style={{ marginTop: 10, bgcolor: "text.main" }}></Divider>
            <div class="items" style={{ marginTop: 30, display: windowWidth < 600 && onSlide ? "none" : "block" }}>
              <Typography variant='body_title1'>Font Type</Typography> <br />
              <ToggleButtonGroup
                color="primary"
                value={fontFamily}
                exclusive
                onChange={handleFontTypeChange}
              >
                <ToggleButton color="text" sx={{ p: 1, backgroundColor: 'white' }} value="Default" size="small" style={{ width: "100px", height: "20px", textTransform: "capitalize", fontSize: "10px" }}> Default </ToggleButton>
                <ToggleButton color="text" sx={{ p: 1, backgroundColor: 'white' }} value="Uthmani" size="small" style={{ width: "100px", height: "20px", textTransform: "capitalize", fontSize: "10px" }}> Uthmani </ToggleButton>

              </ToggleButtonGroup>
            </div>
            <div class="items" style={{ marginTop: 1, display: windowWidth < 600 && onSlide ? "none" : "block" }}>
              <ToggleButtonGroup
                color="primary"
                value={fontFamily}
                exclusive
                onChange={handleFontTypeChange}
              >

                <ToggleButton color="text" sx={{ p: 1, backgroundColor: 'white' }} value="IndoPak" size="small" style={{ width: "100px", height: "20px", textTransform: "capitalize", fontSize: "10px" }}> IndoPak </ToggleButton>
                <ToggleButton color="text" sx={{ p: 1, backgroundColor: 'white' }} value="Tajweed" size="small" style={{ width: "100px", height: "20px", textTransform: "capitalize", fontSize: "10px" }}> Tajweed </ToggleButton>

              </ToggleButtonGroup>
            </div>
            <div class="items" style={{ marginTop: 1, display: windowWidth < 600 && onSlide ? "none" : "block" }}>
              <ToggleButtonGroup
                color="primary"
                value={fontFamily}
                exclusive
                onChange={handleFontTypeChange}
              >

                <ToggleButton color="text" sx={{ p: 1, backgroundColor: 'white' }} value="SimplifiedArabic" size="small" style={{ width: "100px", height: "20px", textTransform: "capitalize", fontSize: "10px" }}> Simplified Arabic </ToggleButton>

              </ToggleButtonGroup>
            </div>

            <Divider style={{ marginTop: 10, marginBottom: 10, bgcolor: "text.main" }}></Divider>
            <div style={{ display: windowWidth < 600 && onSlide ? "none" : "block" }}>
              <div class="d-flex align-items-center">
                <div class="flex-grow-0"><Typography variant='default_body'>Translation</Typography></div>
                <div class="flex-grow-1 d-flex"><div class="checkbox-wrapper"><div class="block"><input data-index="0" id="c2" type="checkbox" checked={isTranslationOn}
                  onChange={handleTranlstaionChange} /><label for="c2"></label></div></div></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="flex-grow-0"><Typography variant='default_body'>Transliteration</Typography></div>
                <div class="flex-grow-1 d-flex"><div class="checkbox-wrapper"><div class="block"><input data-index="0" id="c3" type="checkbox" checked={isTransiliterationOn}
                  onChange={handleTransiliteration} /><label for="c3"></label></div></div></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="flex-grow-0"><Typography variant='default_body'>Arabic</Typography></div>
                <div class="flex-grow-1 d-flex"><div class="checkbox-wrapper"><div class="block"><input data-index="0" id="c4" type="checkbox" checked={isArabicOn}
                  onChange={handleArabic} /><label for="c4"></label></div></div></div>
              </div>
            </div>
          </div>



          <Divider style={{ marginTop: 10, bgcolor: "text.main" }}></Divider>
          <div class="items" style={{ marginTop: 30, display: windowWidth < 600 && onSlide ? "none" : "block" }}>
            <Typography variant='body_title1'>Layout Settings</Typography> <br />
            <ToggleButtonGroup
              color="primary"
              value={viewType}
              exclusive
              onChange={handleViewChange}
            >
              <ToggleButton color="text" sx={{ p: 1, backgroundColor: 'white' }} value="Ayat" size="small" style={{ width: "70px", height: "20px", textTransform: "capitalize", fontSize: "10px" }}>Ayah</ToggleButton>
              <ToggleButton color="text" sx={{ p: 1, backgroundColor: 'white' }} value="Phrase" size="small" style={{ width: "70px", height: "20px", textTransform: "capitalize", fontSize: "10px" }}>Phrase</ToggleButton>
              <ToggleButton color="text" sx={{ p: 1, backgroundColor: 'white' }} value="Paragraph" size="small" style={{ width: "70px", height: "20px", textTransform: "capitalize", fontSize: "10px" }}>Paragraph</ToggleButton>
            </ToggleButtonGroup>

          </div>

        </Box>

      </Menu>

      {location.pathname.includes("/read") &&
        <Box
          component="nav"
          sx={{ backgroundColor: "body.main", width: { sm: col > 1 ? drawerWidth : 0 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {(col < 2 || windowWidth < 600) &&
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              BackdropProps={{ invisible: false }}
              PaperProps={{
                sx: {
                  backgroundColor: "body.main",
                  color: "text.main",
                }
              }}

              sx={{

                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflow: "none", },
              }}
            >
              <LeftChapterDrawer chapters={chapters} onClickChapter={onClickChapter} selectedChapter={selectedChapter} onClickAyatNumber={onClickAyatNumber} selectedAyatNumber={selectedAyatNumber} ref={leftDrawerRef} />
            </Drawer>}
          {col > 1 &&
            <Drawer
              PaperProps={{
                sx: {
                  backgroundColor: "body.main",
                  color: "text.main",
                  overflowY: 'hidden',

                }
              }}
              BackdropProps={{ invisible: true }}
              variant="permanent"
              sx={{
                backgroundColor: "body.main",
                position: "fixed",
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflow: "none", },
              }}
              open
            >
              <LeftChapterDrawer chapters={chapters} onClickChapter={onClickChapter} selectedChapter={selectedChapter} onClickAyatNumber={onClickAyatNumber} selectedAyatNumber={selectedAyatNumber} col={col} ref={leftDrawerRef} />

            </Drawer>
          }
        </Box>
      }

      <Box
        component="main"
        sx={{ backgroundColor: "body.main", flexGrow: 1, width: { sm: `calc(100% - ${col == 3 ? drawerWidth : 0}px)` } }}
      >

        <Routes>
          <Route path="/" exact element={<Main />} />
          <Route path="/home" exact element={<Main />} />
          <Route path="/read" exact element={<Chapter mode={mode} chapter={selectedChapter} chapters={chapters} isArabicOn={isArabicOn} isTransiliterationOn={isTransiliterationOn} isTranslationOn={isTranslationOn} viewType={viewType} setViewType={setViewType} fontSize={fontSize} arabicFontSize={arabicFontSize} fontFamily={fontFamily} col={col} selectedAyatNumber={selectedAyatNumber} setCol={setCol} handleTags={handleTags} handleIsUserLoginCheck={handleIsUserLoginCheck} searchTerm={searchText} setSelectedChapter={setSelectedChapter} setSelectedAyatNumber={setSelectedAyatNumber} />} />
          <Route path="/read/:chapterNumber/:verseNumber?" exact element={<Chapter mode={mode} chapter={selectedChapter} isArabicOn={isArabicOn} isTransiliterationOn={isTransiliterationOn} isTranslationOn={isTranslationOn} viewType={viewType} setViewType={setViewType} fontSize={fontSize} arabicFontSize={arabicFontSize} fontFamily={fontFamily} col={col} selectedAyatNumber={selectedAyatNumber} setCol={setCol} handleTags={handleTags} handleIsUserLoginCheck={handleIsUserLoginCheck} searchTerm={searchText} setSelectedChapter={setSelectedChapter} setSelectedAyatNumber={setSelectedAyatNumber} />} />
          <Route path="/tags" exact element={<Tag handleTags={handleTags} label={onClickTag} setSelectedChapter={setSelectedChapter} setSelectedAyatNumber={setSelectedAyatNumber} navMenuItemClick={navMenuItemClick} setViewType={setViewType} />} />
          <Route path="/allNotes" exact element={<AllNotes setSelectedChapter={setSelectedChapter} />} />

          <Route path="/register" exact element={<Register />} />
          <Route path="/glossary" exact element={<Glossary handleSearch2={handleSearch2} setSelectedChapter={setSelectedChapter} setSelectedAyatNumber={setSelectedAyatNumber} navMenuItemClick={navMenuItemClick} setViewType={setViewType} />} />
          <Route path="/indexes" exact element={<Indexes handleOpenFootNote={handleOpenFootNote} setSelectedChapter={setSelectedChapter} setSelectedAyatNumber={setSelectedAyatNumber} navMenuItemClick={navMenuItemClick} searchTerm={searchText} setViewType={setViewType} />} />
        </Routes>
      </Box>
    </Box>

  );
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default App;


var ayatArray = []
const LeftChapterDrawer = forwardRef(({ chapters, onClickChapter, selectedChapter, onClickAyatNumber, selectedAyatNumber, col }, ref) => {

  const [menuItemList, setMenuItemList] = React.useState([]);
  const [menuItemAyatList, setMenuItemAyatList] = React.useState([]);
  const memoizedChapters = React.useMemo(() => chapters, [chapters]);
  const [searchChapter, setSearchChapter] = React.useState('');
  const [searchVerse, setSearchVerse] = React.useState('');
  const chapterInputRef = React.useRef(null);

  // Expose reset function to parent via ref
  useImperativeHandle(ref, () => ({
    resetSearchFields: () => {
      setSearchChapter('');
      setSearchVerse('');
      onSearch();
      onSearchAyat();
    }
  }));

  React.useEffect(() => {

    ayatArray = []
    try {
      const totalAyats = parseInt(selectedChapter.totalAyats);
      for (let i = 1; i <= totalAyats; i++) {
        ayatArray.push(i);
      }
    } catch {

    }
    setMenuItemList(memoizedChapters)
    setMenuItemAyatList(ayatArray)
  }, [memoizedChapters, selectedChapter]);




  // Function to normalize the search term to handle variations in character encoding
  function normalize(term) {
    if (!term) return "";
    return term
      .toLowerCase() // Ensure lowercase comparison
      .normalize("NFD") // Decompose Unicode characters (e.g., ā -> a + ˉ)
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/[`'´‘’ʻʿ]/g, "") // Remove special punctuation
      .replace(/-/g, ""); // Remove hyphens if necessary
  }


  const onSearch = (event) => {
    // Access input value
    const query = event?.target.value || '';
    if (query.trim() != "") {
      // Create copy of item list
      var _menuItemList = [...chapters];
      // Include all elements which includes the search query
      _menuItemList = _menuItemList.filter((item) => normalize(item.title).toLowerCase().includes(query.toLowerCase()) || item.title.toLowerCase().includes(query.toLowerCase()) || item.number.includes(query));
      // Trigger render with updated values
      setMenuItemList(_menuItemList);
    }
    else {
      setMenuItemList(memoizedChapters)
    }
    // Restore focus after state update
    setTimeout(() => {
      chapterInputRef.current?.focus();
    }, 0);
  };

  const onSearchAyat = (event) => {
    // Access input value
    const query = event?.target.value || '';
    if (query.trim() != "") {
      // Create copy of item list
      var _menuItemList = [...ayatArray];
      // Include all elements which includes the search query
      _menuItemList = _menuItemList.filter((item) => item === parseInt(query));
      // Trigger render with updated values
      setMenuItemAyatList(_menuItemList);
    }
    else {
      setMenuItemAyatList(ayatArray)
    }

  };


  const boxStyles = {
    height: '83vh',
    overflowY: 'auto',
    scrollbarWidth: 'thin', // For Firefox
    scrollbarColor: '#a0a0a0 transparent', // For Firefox
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#a0a0a0',
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a0',
    },
  };

  const theme = useTheme();

  const inputStyle = {
    color: theme.palette.text.main, // Example: Accessing primary color from the theme
    borderColor: theme.palette.text.main, // Example: Accessing secondary color from the theme
    // Add any other styles as needed
    //border: `0.5px solid ${theme.palette.text.main}`, // Example: Accessing secondary color from the theme
    borderRadius: theme.shape.borderRadius,
  };



  return (
    <Grid container>
      <Grid item sx={{ width: "170px" }}>
        {col == 3 &&
          <Toolbar sx={{ pt: "70px", display: { xs: 'none', sm: 'block' } }} />
        }
        <TextField
          InputProps={{
            style: inputStyle,
          }}
          inputRef={chapterInputRef}
          variant="outlined"
          size="small"
          sx={{ m: 1, flex: 1, mt: 2 }}
          placeholder="Search Chapter"
          inputProps={{ 'aria-label': 'Search Chapter' }}
          value={searchChapter}
          onChange={(event) => {
            setSearchChapter(event.target.value);
            onSearch(event);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && menuItemList.length > 0) {
              onClickChapter(menuItemList[0]);
            }
          }}
        />


        <Box sx={boxStyles}>
          <List>

            {menuItemList.map((item, index) => (
              <>
                <ListItem key={index} disablePadding >
                  <ListItemButton onClick={(event) => { onClickChapter(item) }} autoFocus={item.id == selectedChapter.id ? true : false} disabled={item.id == selectedChapter.id ? true : false} >
                    <ListItemText primary={<Typography variant="listItem" >{item.number}. {item.title}</Typography>} />
                  </ListItemButton>
                </ListItem>
              </>
            ))}
            <ListItem disablePadding >
              <ListItemButton>
                <ListItemText primary={<Typography variant="listItem"></Typography>} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton>
                <ListItemText primary={<Typography variant="listItem"></Typography>} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton>
                <ListItemText primary={<Typography variant="listItem"></Typography>} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Grid>
      <Grid item sx={{ width: "80px", borderLeft: 1, borderColor: 'divider' }} >
        {col == 3 &&
          <Toolbar sx={{ pt: "70px", display: { xs: 'none', sm: 'block' } }} />
        }
        {/* <Toolbar>Verse</Toolbar> */}
        <TextField
          InputProps={{
            style: inputStyle,
          }}
          variant="outlined"
          size="small"
          sx={{ ml: 1, flex: 1, mt: 2 }}
          placeholder="Verse"
          inputProps={{ 'aria-label': 'Search Verse' }}
          value={searchVerse}
          onChangeCapture={(event) => {
            setSearchVerse(event.target.value);
            onSearchAyat(event);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && menuItemAyatList.length > 0) {
              onClickAyatNumber(menuItemAyatList[0]);
            }
          }}
        />


        <Box sx={boxStyles}>
          <List >
            {

              menuItemAyatList.map((item, index) => (
                <>
                  <ListItem key={index} disablePadding >
                    <ListItemButton onClick={(event) => { onClickAyatNumber(item) }} autoFocus={selectedAyatNumber == item ? true : false} disabled={selectedAyatNumber == item ? true : false} sx={{ textAlign: "center" }}>
                      <ListItemText primary={<Typography variant="listItem">{item}</Typography>} />
                    </ListItemButton>
                  </ListItem>
                </>

              ))}
            <ListItem disablePadding >
              <ListItemButton>
                <ListItemText primary={<Typography variant="listItem"></Typography>} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton>
                <ListItemText primary={<Typography variant="listItem"></Typography>} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton>
                <ListItemText primary={<Typography variant="listItem"></Typography>} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Grid>
  );


});