import { Box, CircularProgress, Container, Grid, InputAdornment, TextField, Typography, Select, MenuItem, Switch, FormControlLabel, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { searchByWord } from "./Api";
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";

let uniqueAyatsArray = [];
let uniqueChaptersArray = [];
export default function Indexes({ handleOpenFootNote, setSelectedChapter, setSelectedAyatNumber, navMenuItemClick, searchTerm, setViewType }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedSuraFilter, setSelectedSuraFilter] = useState("All");
  const [showTranslation, setShowTranslation] = useState(false);
  const [ayats, setAyats] = useState([]);

  const itemsPerPage = 10; // Set how many ayats to display per page

  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    if (searchTerm !== "") {
      const pattern = /^(\d+):(\d+)$/;

      if (searchTerm.match(pattern)) {
        window.location.href = `/read/${searchTerm}`
        return;
      }

      setIsLoading(true);
      searchByWord(searchTerm.toString().trim()).then((result) => {
        uniqueAyatsArray = [];
        setAyats(result.ayats);
        setSelectedFilter("All");
        setSelectedSuraFilter("All");
        result.ayats?.map((ayat, index) => {
          if (uniqueAyatsArray.findIndex(item => item.ayatNumber == ayat.ayatNumber) === -1) {
            uniqueAyatsArray.push(ayat)
          }
        });
        uniqueChaptersArray = Array.from(
          new Map(result.ayats?.map((item) => [item.chapterTitle, item])).values()
        );

        setIsLoading(false);
      }).catch(console.error.bind(console))
    }
  }, [searchTerm]);




  function mySearchFunc(str, lookup) {
    const regex = new RegExp(`(?:\\S+\\s+){0,3}${lookup}(?:\\s+\\S+){0,3}`, 'giu');


    let m;
    let result = [];
    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      if (m)
        //console.log(`Found: ${m}`);
        result.push(m[0]);
    }
    return result.join(' ... ');
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  }

  let filteredAyats = ayats;

  // Apply first filter based on Makki/Madni
  if (selectedFilter !== "All") {
    filteredAyats = filteredAyats.filter((ayat) => ayat.chapterType === selectedFilter);
  }

  // Apply second filter based on selected Surah
  if (selectedSuraFilter !== "All") {
    filteredAyats = filteredAyats.filter((ayat) => ayat.chapterTitle === selectedSuraFilter);
  }

  // Calculate total pages
  const totalPages = Math.ceil(filteredAyats.length / itemsPerPage);

  // Get the current page's data
  const paginatedAyats = filteredAyats.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    setCurrentPage(1);
  };
  const handleSuraFilterChange = (event) => {
    setSelectedSuraFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleToggleChange = (event) => {
    setShowTranslation(event.target.checked);
  };

  function boldString(fString, bString) {

    // Escape the search term before using it in a regular expression
    let escapedSearchTerm = escapeRegExp(bString);

    // Normalize the search term to handle variations in character encoding
    let normalizedSearchTerm = normalize(escapedSearchTerm);

    // Function to normalize the search term to handle variations in character encoding
    function normalize(term) {
      // Replace ASCII characters with their Unicode equivalents
      let normalizedTerm = term.replace(/[A-Za-z]/g, function (c) {
        return String.fromCharCode(c.charCodeAt(0) | 0x20);
      });
      // Normalize Unicode characters
      return normalizedTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    // Normalize the text to handle variations in character encoding
    let normalizedText = normalize(fString);

    normalizedText = mySearchFunc(normalizedText, normalizedSearchTerm)
    // Replace each occurrence of the search term with the highlighted version
    let str = normalizedText.replace(new RegExp(`(${escapedSearchTerm}|${normalizedSearchTerm})`, 'gi'), function (match) {
      match = bString
      return `<span style="color:#FF8C00"><b>${match}</b></span>`;
    });
    return str;

    let regex = new RegExp(str, 'gi');

    return;
    const regex2 = new RegExp("(?:\\w+[\\s,;:'\".!?]+){0,3}" + bString + "(?:[\\s,;:'\".!?]+\\w+){0,3}", "gm");
    let m;
    let result = [];
    while ((m = regex2.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex2.lastIndex) {
        regex2.lastIndex++;
      }

      if (m)
        //console.log(`Found: ${m}`);
        result.push(m[0]);
    }
    return result.join(' ... ');
    // var max = 500
    // //replace 15 with 500
    // return str.length > max ? str.substring(0, max) + '...' : str

    //return  
  }


  return (
    <Box

      alignItems="center"
      mt={12}
      sx={{
        width: "100%", display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >

      <Box px={5} pt={2} >
        {isLoading ? <CircularProgress color="text" /> :
          <>
            {ayats.length > 0 ?
              <>
                <Typography variant="default_body"><b>Total Number Occurrence:</b> {ayats.length}</Typography><br /></> : <p>No Results Found!</p>}
            <br />

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" }, // Column on small screens, row on larger screens
                justifyContent: "space-between",
                alignItems: { xs: "flex-start", sm: "flex-start", md: "center" }, // Align left for small screens
                width: "100%",
                maxWidth: 720,
                mb: 2,
                p: 2, // Add padding for the section
                border: "1px solid #e0e0e0", // Add border for a section feel
                borderRadius: "8px", // Rounded corners
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional: Subtle shadow for depth
                backgroundColor: "#f9f9f9", // Light background color for distinction
                gap: { xs: 2, sm: 2, md: 0 }, // Add spacing between items for small screens
              }}
            >
              {/* Dropdown for filter */}
              <Select
                value={selectedFilter}
                onChange={handleFilterChange}
                sx={{
                  mb: { xs: 1, sm: 1, md: 0 }, // Margin bottom for spacing on small screens
                  mr: { xs: 0, sm: 0, md: 1 }, // Margin right for spacing on small screens
                  minWidth: 150, // Set a minimum width
                  bgcolor: "white", // Ensure dropdown has a white background
                  border: "1px solid #ced4da", // Border to match a modern input style
                  borderRadius: "4px",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Optional: Add a slight shadow
                  "& .MuiSelect-select": {
                    padding: "10px", // Adjust padding for better spacing
                  },
                  "&:hover": {
                    borderColor: "#90caf9", // Light blue border on hover
                  },
                  "&.Mui-focused": {
                    borderColor: "#42a5f5", // Blue border when focused
                    boxShadow: "0 0 0 2px rgba(66, 165, 245, 0.25)", // Add focus ring
                  },
                }}
                displayEmpty
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Makki">Makki</MenuItem>
                <MenuItem value="Madni">Madni</MenuItem>
              </Select>

              <Select
                value={selectedSuraFilter}
                onChange={handleSuraFilterChange}
                sx={{
                  mb: { xs: 1, sm: 1, md: 0 }, // Margin bottom for spacing on small screens
                  mr: { xs: 0, sm: 0, md: 1 }, // Margin right for spacing on small screens
                  minWidth: 150,
                  bgcolor: "white",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                  "& .MuiSelect-select": {
                    padding: "10px",
                  },
                  "&:hover": {
                    borderColor: "#90caf9",
                  },
                  "&.Mui-focused": {
                    borderColor: "#42a5f5",
                    boxShadow: "0 0 0 2px rgba(66, 165, 245, 0.25)",
                  },
                }}
                displayEmpty
              >
                <MenuItem value="All">All</MenuItem>
                {uniqueChaptersArray.map((chapter) => (
                  <MenuItem key={chapter.chapterTitle} value={chapter.chapterTitle}>
                    {chapter.chapterTitle}
                  </MenuItem>
                ))}
              </Select>

              {/* Toggle */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body1">Display Translation/References</Typography>
                <Switch checked={showTranslation} onChange={handleToggleChange} color="primary" />
              </Box>
            </Box>


            {

              paginatedAyats.map((ayat, index) => (
                <Box
                  key={index}
                  pt={2}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedChapter({
                      id: ayat.chapterId,
                      title: ayat.chapterTitle,
                      arabicTitle: ayat.chapterArabicTitle,
                      totalAyats: ayat.totalAyats
                    });
                    setSelectedAyatNumber(ayat.ayatNumber);
                    setViewType("Ayat");
                    navMenuItemClick("read");
                  }}
                >
                  <Typography variant="default_body">
                    <b>{ayat.chapterTitle} - {ayat.number}:{ayat.ayatNumber}</b>
                  </Typography>
                  <br />
                  {showTranslation && (
                    <>
                      {ayat.context && boldString(ayat.context + "", searchTerm) && (
                        <Typography variant="default_body">
                          <b>Foot Note:</b> <span dangerouslySetInnerHTML={{ __html: boldString(ayat.context + "", searchTerm) }} />
                        </Typography>
                      )}
                      <Typography variant="default_body" dangerouslySetInnerHTML={{ __html: boldString(ayat.translation + "", searchTerm) }} />
                      {ayat.reference && boldString(ayat.reference + "", searchTerm) && (
                        <Typography onClick={() => handleOpenFootNote(ayat.reference)} variant="default_body">
                          <b>Reference:</b> <span dangerouslySetInnerHTML={{ __html: boldString(ayat.reference + "", searchTerm) }} />
                        </Typography>
                      )}
                    </>
                  )}
                  <Divider />
                </Box>
              ))
            }

            {/* Pagination Controls */}
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="contained"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                Previous
              </Button>
              <Typography variant="body1" sx={{ mx: 2 }}>
                Page {currentPage} of {totalPages}
              </Typography>
              <Button
                variant="contained"
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                Next
              </Button>
            </Box>
          </>
        }
      </Box>
    </Box>

  );
}